import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import css from "./page-2.module.css"
import cn from "classnames"

const About = () => (
  <Layout>
    <Link to="/">Home</Link>
    <SEO title="About" />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <a href="https://www.linkedin.com/in/hannahkmurphy/">
      Visit my Linkedin for further information
    </a>
    <br></br>
    <br></br>
    <a href="https://github.com/impactyogi">
      Visit my Github for further information
    </a>
    <br></br>
    <br></br>
    <p>
      Please note formating and further info coming soon pending site completion
    </p>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br> <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <ul></ul>
  </Layout>
)

export default About
